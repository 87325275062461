import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import {
  ImageErrorEventData,
  ImageLoadEventData,
  NativeSyntheticEvent,
  Image as RNImage,
  ImageProps as RNImageProps,
} from "react-native";
import { ImageLoadTimeoutError } from "./ImageLoadTimeoutError";

const MAIN_IMAGE_LOAD_TIMEOUT = 3000;

interface ImageProps extends Omit<RNImageProps, "source" | "src" | "srcSet"> {
  readonly src: string;
  readonly onMainImageLoadError?: (error: ImageLoadTimeoutError) => void;
  readonly fallbackSrc?: string;
  readonly fallbackTimeout?: number;
}

const Image: FC<ImageProps> = ({
  src,
  fallbackSrc,
  onMainImageLoadError,
  onError,
  onLoad,
  fallbackTimeout = MAIN_IMAGE_LOAD_TIMEOUT,
  ...restProps
}) => {
  const [imageSrc, setImageSrc] = useState(src);
  useEffect(() => setImageSrc(src), [src]);

  const fallbackTimeoutRef = useRef<NodeJS.Timeout>();
  const isFallbackSrcRef = useRef(false);
  isFallbackSrcRef.current = imageSrc === fallbackSrc;

  const handleOnLoadStart = useCallback(() => {
    if (isFallbackSrcRef.current) {
      return;
    }

    fallbackTimeoutRef.current && clearTimeout(fallbackTimeoutRef.current);
    fallbackTimeoutRef.current = setTimeout(() => {
      fallbackSrc && setImageSrc(fallbackSrc);
      onMainImageLoadError?.(new ImageLoadTimeoutError(imageSrc));
    }, fallbackTimeout);
  }, [fallbackSrc, fallbackTimeout, imageSrc, onMainImageLoadError]);

  const handleOnImageLoaded = useCallback(
    (event: NativeSyntheticEvent<ImageLoadEventData>) => {
      fallbackTimeoutRef.current && clearTimeout(fallbackTimeoutRef.current);
      fallbackTimeoutRef.current = undefined;

      onLoad?.(event);
    },
    [onLoad],
  );

  const handleOnError = useCallback(
    (event: NativeSyntheticEvent<ImageErrorEventData>) => {
      fallbackSrc && setImageSrc(fallbackSrc);
      onError?.(event);
    },
    [fallbackSrc, onError],
  );

  return (
    <RNImage
      {...restProps}
      source={{ uri: imageSrc }}
      onError={handleOnError}
      onLoad={handleOnImageLoaded}
      onLoadStart={handleOnLoadStart}
    />
  );
};

export { Image };
