import React, { FC, useCallback } from "react";
import { FlatList, Pressable } from "react-native";
import { useMediaImage, useScreenSize } from "@lookiero/sty-psp-ui";
import { ReplaceableByProjection } from "../../../../../projection/boxPreview/boxPreview";
import { mainImageFromMedia } from "../../../../../projection/media/media";
import { useEnvironment } from "../../../../projection/environment/react/useEnvironment";
import { ProductVariantImage } from "../../molecules/productVariantImage/ProductVariantImage";
import { style } from "./ColorPicker.style";

interface ColorPickerProps {
  readonly replacedFor: ReplaceableByProjection;
  readonly replaceableBy: ReplaceableByProjection[];
  readonly onChanged: (productVariant: ReplaceableByProjection) => void;
}
const ColorPicker: FC<ColorPickerProps> = ({ replacedFor, replaceableBy, onChanged }) => {
  const {
    media: {
      sizes: { default: defaultMediaSize },
    },
  } = useEnvironment();
  const cdnImageUrl = useMediaImage();

  const screenSize = useScreenSize();
  const colorPickerStyle = screenSize === "L" ? style.colorPickerLarge : style.colorPickerSmall;

  const handleOnChange = useCallback(
    (productVariant: ReplaceableByProjection) => onChanged(productVariant),
    [onChanged],
  );

  const renderItem = useCallback(
    ({ item }: { item: ReplaceableByProjection }) => {
      const media = mainImageFromMedia(item.media);

      return (
        <Pressable
          style={[style.productVariant, replacedFor.id === item.id && style.productVariantActive]}
          testID="color-picker-product-variant"
          onPress={() => handleOnChange(item)}
        >
          <ProductVariantImage
            fallbackImage={media.url}
            image={cdnImageUrl({
              url: media.url,
              width: defaultMediaSize,
            })}
          />
        </Pressable>
      );
    },
    [cdnImageUrl, defaultMediaSize, handleOnChange, replacedFor.id],
  );

  return (
    <FlatList
      contentContainerStyle={[style.contentContainer, colorPickerStyle]}
      data={replaceableBy}
      keyExtractor={(item) => item.id}
      renderItem={renderItem}
      showsHorizontalScrollIndicator={false}
      style={style.flatList}
      testID="color-picker"
      horizontal
    />
  );
};

export { ColorPicker };
