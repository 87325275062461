class ImageLoadTimeoutError extends Error {
  public src: string;
  public constructor(src: string) {
    super();
    this.name = "ImageLoadTimeoutError";
    this.src = src;
  }
}

export { ImageLoadTimeoutError };
