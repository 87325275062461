import VisibilitySensor from "@svanboxel/visibility-sensor-react-native";
import React, { FC, useState, useCallback, useRef, useEffect } from "react";
import { ImageErrorEventData, NativeSyntheticEvent } from "react-native";
import { Motion } from "@lookiero/aurora";
import { useLogger } from "@lookiero/sty-psp-logging";
import { AspectRatioView } from "@lookiero/sty-psp-ui";
import { Image } from "../../atoms/image/Image";
import { ImageLoadTimeoutError } from "../../atoms/image/ImageLoadTimeoutError";
import { style } from "./ProductVariantImage.style";

interface ProductVariantImageProps {
  readonly image: string;
  readonly fallbackImage?: string;
  readonly onLoaded?: () => void;
  readonly onImageView?: () => void;
}
const ProductVariantImage: FC<ProductVariantImageProps> = ({
  image,
  fallbackImage,
  onLoaded,
  onImageView,
}: ProductVariantImageProps) => {
  const mountedRef = useRef(true);
  const logger = useLogger();

  const [visible, setVisible] = useState(false);
  const handleOnImageLoaded = useCallback(() => {
    setVisible(true);
    onLoaded?.();
  }, [onLoaded]);
  const motionValue = { opacity: visible ? 1 : 0 };

  const handleOnError = useCallback(
    (error: NativeSyntheticEvent<ImageErrorEventData>) => logger.captureException(error.nativeEvent.error),
    [logger],
  );
  const handleOnMainImageLoadError = useCallback(
    (error: ImageLoadTimeoutError) => logger.captureException(error),
    [logger],
  );

  const visibilitySensorStyle = { style: { flex: 1 } };
  const handleImageVisibility = useCallback(
    (isVisible: boolean) => {
      if (!mountedRef.current) {
        return;
      }

      if (isVisible) {
        onImageView?.();
      }
    },
    [onImageView],
  );

  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  return (
    <Motion value={motionValue}>
      <AspectRatioView aspectRatio={1.25}>
        <VisibilitySensor onChange={handleImageVisibility} {...visibilitySensorStyle}>
          <Image
            fallbackSrc={fallbackImage}
            resizeMode="cover"
            src={image}
            style={style.image}
            testID="product-variant-image"
            onError={handleOnError}
            onLoad={handleOnImageLoaded}
            onMainImageLoadError={handleOnMainImageLoadError}
          />
        </VisibilitySensor>
      </AspectRatioView>
    </Motion>
  );
};

export { ProductVariantImage };
