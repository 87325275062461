import { Platform } from "react-native";
import { StyleSheet } from "react-native";
import { theme } from "@lookiero/sty-psp-ui";

const { colorBgBase, borderRadius4, space4, space6, space5 } = theme();

const style = StyleSheet.create({
  bodyColumn: {
    gap: space4,
  },
  bodyRow: {
    paddingTop: space6,
  },
  garment: {
    width: "50%",
    ...Platform.select({
      web: {
        cursor: "pointer",
      },
    }),
  },
  look: {
    borderRadius: borderRadius4,
    overflow: "hidden",
  },
  lookItemDefault: {
    paddingBottom: space6,
    paddingLeft: space6 / 2,
    paddingRight: space6 / 2,
  },
  lookItemSmall: {
    paddingBottom: space4,
    paddingLeft: space4 / 2,
    paddingRight: space4 / 2,
  },
  lookTemplate: {
    paddingVertical: space6,
  },
  otherGarments: {
    alignItems: "center",
    backgroundColor: colorBgBase,
    borderRadius: borderRadius4,
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
    paddingHorizontal: space4,
    paddingVertical: space6,
  },
  otherGarmentsTitle: {
    marginBottom: space5,
  },
});

export { style };
